import React from "react"
import { toast } from "react-toastify"
import { detect } from "detect-browser"
import "react-toastify/dist/ReactToastify.css"
import "./src/styles/fonts.css"
import Layout from "./src/components/ui/layout"
import NavigationContextProvider from "./src/context/navigation-context-provider"

const browser = detect()
if (browser?.name === "edge" || browser?.name === "ie") {
  require("./src/styles/ie-only.css")
}

toast.configure()

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => {
  return <NavigationContextProvider>{element}</NavigationContextProvider>
}
