import React, { useState } from "react"
import styled from "styled-components"
import handleViewport from "react-in-viewport"
import vars from "../../styles/vars"
import SocialBlocks from "./social-blocks"
import FooterNav from "./footer-nav"
import ContactBar from "./contact-bar"

const FooterBlock = props => {
  const { forwardedRef } = props
  return (
    <StyledFooter ref={forwardedRef}>
      <div className="container text-center text-md-left">
        <div className="row">
          <div className="col-12 d-none d-md-block col-md">
            <FooterNav />
          </div>
          <div className="col-12 col-md text-center">
            <div className="mb-3 d-none d-md-block">
              <a href="tel:0191 265 8585">0191 265 8585</a>
            </div>
            <div className="mb-3 mb-md-0">
              <SocialBlocks />
            </div>
          </div>

          <div className="col-12 col-md text-center">
            <div className="row justify-content-center justify-content-md-end">
              <div className="col-auto">
                <a href="https://www.inspiredagency.co.uk">
                  <div className="mb-1">Part of the</div>
                  <img
                    src={require("../../assets/images/logos/inspired-logo-v1.png")}
                    className="img-responsive"
                    alt="inspired agency logo"
                    loading="lazy"
                  />
                  <div>Family</div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <FooterBottom className="row mt-2">
          <div className="col-12 col-md mt-2">
            The Victoria Building, Heaton, Park View, Newcastle upon Tyne NE6
            5BF
          </div>
          <div className="col-12 col-md-auto mt-2">
            Company Reg No: 06145221
          </div>
        </FooterBottom>
      </div>
    </StyledFooter>
  )
}

const ViewportFooterBlock = handleViewport(FooterBlock)

const Footer = () => {
  const [isContactBarFixed, setIsContactBarFixed] = useState(true)

  const handleFooterVisible = () => setIsContactBarFixed(false)
  const handleFooterHidden = () => setIsContactBarFixed(true)

  return (
    <FooterContainer>
      <ContactBar isFixed={isContactBarFixed} />
      <ViewportFooterBlock
        onEnterViewport={handleFooterVisible}
        onLeaveViewport={handleFooterHidden}
      />
    </FooterContainer>
  )
}

export default Footer

const FooterContainer = styled.div`
  position: relative;
  padding-top: 127px;

  @media (max-width: ${vars.screen_xs_max}) {
    padding-top: 118px;
  }
`

const StyledFooter = styled.footer`
  background: ${vars.black};
  padding: 2rem 0;
  color: ${vars.white};
  position: relative;
  line-height: 1.3rem;

  :before {
    content: "";
    position: absolute;
    top: -5px;
    z-index: 10;
    left: 0;
    width: 100%;
    height: 5px;
    display: block;
    background: rgb(69, 197, 196);
    background: linear-gradient(
      90deg,
      rgba(69, 197, 196, 1) 0%,
      rgba(122, 117, 157, 1) 25%,
      rgba(225, 93, 104, 1) 50%,
      rgba(244, 139, 94, 1) 75%,
      rgba(255, 209, 87, 1) 100%
    );
  }

  a {
    color: ${vars.white};
  }
`

const FooterBottom = styled.section`
  color: ${vars.darkGrey};
  font-size: 0.9rem;
`
