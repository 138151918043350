import React from "react"

const LoadingAnimation = () => {
  return (
    <img
      alt="Loading..."
      src={require("../../assets/svg/animations/puff.svg")}
      style={{ width: 34, marginTop: 4 }}
    />
  )
}

export default LoadingAnimation
