// Styled component variables.

// Colours
const black = "#000000"
const white = "#ffffff"
const purple = "#af02ed"
const orange = "#E65C61"
const yellow = "#FED35C"
const crimson = "#DC143C"

const lightGrey = "#f1f1f1"
const grey = "#cccccc"
const darkGrey = "#999999"
const darkerGrey = "#5a5a5a"

const paleOrange = "#e55c61"

const primaryColour = orange
const secondaryColour = yellow

const primaryGradient = `linear-gradient(90deg, ${yellow}, ${orange});`

const blackTransparent = (transparency = "0.5") =>
  `rgba(0, 0, 0, ${transparency})`

const gradientColourGroup = [
  "#f9d423, #ff4e50, #f9d423",
  "#23b6c4, #009540, #23b6c4",
  "#2af598, #009efd, #2af598",
  "#0250c5, #e73189, #0250c5",
  "#f74533, #fe6699, #f74533",
  "#cb45ed, #fa6edc, #cb45ed",
  "#20b7c6, #91e6b4, #20b7c6",
]

// Fonts
const fontMont = "Mont, sans-serif"
const primaryFont = fontMont

// Breakpoints
const screen_xs_max = "575px"

const screen_sm_min = "576px"
const screen_sm_max = "767px"

const screen_md_min = "768px"
const screen_md_max = "991px"

const screen_lg_min = "992px"
const screen_lg_max = "1199px"

const screen_xl_min = "1200px"

export default {
  // Colours
  black,
  white,
  purple,
  crimson,
  lightGrey,
  grey,
  darkGrey,
  darkerGrey,
  yellow,
  orange,
  paleOrange,
  primaryColour,
  secondaryColour,
  primaryGradient,
  blackTransparent,
  gradientColourGroup,

  // Fonts
  fontMont,
  primaryFont,

  // Breakpoints
  screen_xs_max,
  screen_sm_min,
  screen_sm_max,
  screen_md_min,
  screen_md_max,
  screen_lg_min,
  screen_lg_max,
  screen_xl_min,
}
