import React, { useReducer, createContext } from "react"

export const NavigationStateContext = createContext()
export const NavigationDispatchContext = createContext()

const initialState = {
  isNavOpen: false,
  activeParentSlug: "",
  isMobileNavOpen: false,
}

function reducer(state, action) {
  switch (action.type) {
    case "OPEN_NAV": {
      return {
        ...state,
        isNavOpen: true,
        activeParentSlug: action.parentSlug,
      }
    }
    case "CLOSE_NAV": {
      return {
        ...state,
        isNavOpen: false,
        activeParentSlug: "",
      }
    }
    case "TOGGLE_MOBILE_NAV": {
      return {
        ...state,
        isMobileNavOpen: !state.isMobileNavOpen,
      }
    }
    default:
      throw new Error(`Invalid action for navigation context: ${action.type}`)
  }
}

const NavigationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <NavigationStateContext.Provider value={state}>
      <NavigationDispatchContext.Provider value={dispatch}>
        {children}
      </NavigationDispatchContext.Provider>
    </NavigationStateContext.Provider>
  )
}

export default NavigationContextProvider
