export function convertCmsDomainToLiveDomain(url) {
  return url.replace("digitisable.wpengine.com", "digitisable.co.uk")
}

export function convertToInternalUrl(url) {
  if (url) {
    return url
      .replace("http://", "")
      .replace("https://", "")
      .replace("cms.digitisable.io", "")
      .replace("cms.digitisable.co.uk", "")
      .replace("digitisable.wpengine.com", "")
  }
  return url
}

export function getRandomInt(maxValue) {
  return Math.floor(Math.random() * maxValue)
}

export default {
  convertCmsDomainToLiveDomain,
  convertToInternalUrl,
  getRandomInt,
}
