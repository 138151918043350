import React from "react"
import styled from "styled-components"
import vars from "../../styles/vars"
import Button from "../common/button"

const ContactBar = ({ isFixed }) => {
  return (
    <StyledContactBar isFixed={isFixed}>
      <div className="container text-center text-lg-left">
        <div className="row align-items-center">
          <div className="col-12 col-sm-auto-6 col-lg-auto d-none d-lg-block">
            <h2 className="m-0">Contact Us</h2>
          </div>
          <div className="col-12 col-sm-auto-6 col-lg d-none d-lg-block">
            <p className="m-0">Ready to digitise your business?</p>
          </div>
          <div className="col-6 col-sm-6 col-lg-auto">
            <Button to="tel:01912658585">Call Us</Button>
          </div>
          <div className="col-6 col-sm-6 col-lg-auto">
            <Button to="/contact-us/">Enquire Now</Button>
          </div>
        </div>
      </div>
    </StyledContactBar>
  )
}

export default ContactBar

const StyledContactBar = styled.section`
  background: ${vars.black};
  color: ${vars.white};
  padding: 1.1rem 0;
  position: ${props => (props.isFixed ? "fixed" : "absolute")};
  bottom: ${props => (props.isFixed ? 0 : "unset")};
  top: ${props => (props.isFixed ? "unset" : "34px")};
  width: 100%;
  z-index: 20;
  border-top: 3px solid ${vars.secondaryColour};

  @media (max-width: ${vars.screen_xs_max}) {
    padding: 0.8rem 0;
    a {
      min-width: 100%;
    }
  }
`
