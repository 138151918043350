import React from "react"
import PropTypes from "prop-types"
import { createGlobalStyle } from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import "bootstrap-4-grid/css/grid.min.css"
import vars from "../../styles/vars"
import Helmet from "react-helmet"
import Footer from "./footer"

const Layout = ({ children, location }) => {
  const animationVariants = {
    initial: {},
    animate: {
      transition: {
        duration: 0.1,
        when: "beforeChildren",
      },
    },
    exit: {
      transition: {
        duration: 0.1,
        when: "afterChildren",
      },
    },
  }

  return (
    <>
      <Helmet />
      <GlobalStyle />
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={location.pathname}
          variants={animationVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          className="main-container"
        >
          <main>{children}</main>
          <Footer />
        </motion.div>
      </AnimatePresence>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  location: {},
}

export default Layout

const GlobalStyle = createGlobalStyle`

  body {
    margin: 0;
    color: ${vars.black};
    font-family: ${vars.primaryFont};
    font-size: 16px;
  }

  .main-container{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
  
  main{
    flex-grow: 1;
  }

  h1, .h1, h2, .h2, h3, .h3 {
    font-weight: 700;
  }

  h1, .h1 {
    font-size: 3.2rem;

    @media (max-width: ${vars.screen_sm_max}) {
      font-size: 2.5rem;
    }
  }

  h2, .h2 {
    font-size: 2rem;
    @media (max-width: ${vars.screen_sm_max}) {
      font-size: 1.5rem;
    }
  }

  p {
    line-height: 1.5rem;
  }

  a {
    text-decoration: none;
  }

  .img-fluid {
    max-width: 100%;
  }
`
