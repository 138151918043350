import React from "react"

const SocialBlocks = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-auto">
        <a
          href="https://twitter.com/iwantinspired"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("../../assets/images/icons/twitter.png")}
            alt="twitter icon"
            loading="lazy"
          />
        </a>
      </div>
      {/* <div className="col-auto">
        <a href="/" target="_blank" rel="noopener noreferrer">
          <img
            src={require("../../assets/images/icons/instagram.png")}
            alt="instagram icon"
          />
        </a>
      </div>
      <div className="col-auto">
        <a href="/" target="_blank" rel="noopener noreferrer">
          <img
            src={require("../../assets/images/icons/facebook.png")}
            alt="facebook icon"
          />
        </a>
      </div> */}
    </div>
  )
}

export default SocialBlocks
