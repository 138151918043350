import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import vars from "../../styles/vars"
import { convertToInternalUrl } from "../../utils/global-util"

const FooterNav = () => {
  const data = useStaticQuery(graphql`
    query FooterNavQuery {
      wordpressWpApiMenusMenusItems(slug: { eq: "footer-nav" }) {
        slug
        items {
          title
          object_slug
          wordpress_id
          url
        }
        wordpress_id
      }
    }
  `)

  const { wordpressWpApiMenusMenusItems: footerNav } = data

  if (footerNav) {
    return (
      <div className="row">
        {footerNav.items.map(navItem => (
          <div className="col-6 mb-2" key={navItem.wordpress_id}>
            <FooterLink to={convertToInternalUrl(navItem.url)}>
              {navItem.title}
            </FooterLink>
          </div>
        ))}
        <div className="col-6 mb-2">
          <FooterLink to="/sitemap/">Sitemap</FooterLink>
        </div>
      </div>
    )
  }

  return null
}

export default FooterNav

const FooterLink = styled(Link)`
  color: ${vars.white};
  text-decoration: none;
  &:hover {
    color: ${vars.secondaryColour};
  }
`
